import React, { useEffect, useState} from 'react'

import "./styles/projectinstallations.css";

const ProjectInstallations = () => {

  const [featuredInstallations, setFeaturedInstallations] = useState([]);

  const fetchFeaturedInstallations = async () => {
    const featuredInstallationsApiCall = await fetch('/wp-json/wp/v2/installations/?per_page=100&filter[meta_key]=featured_installation&filter[meta_value]=1');
    const fiContent = await featuredInstallationsApiCall.json();
    setFeaturedInstallations(fiContent);
  }

  useEffect(() => {
    fetchFeaturedInstallations();
  }, [])

  return (
    <>
      { featuredInstallations.length > 0 &&
        <div className="project-installs-wrapper">
          <div className="project-installs-header">
            <h4 className="p-3">Project Installations</h4>
            <p className="text-center">Signature is driven to achieve unique flooring solutions that expand each client's vision. See how we make signature spaces a reality.</p>
            <p className="text-center">
              <a href="/installations" className="project-installs-explore-button">Explore All Projects</a>
            </p>
          </div>
          <div className="featured-project-installs">
            <div className="row">
              { featuredInstallations.map((install) => (
                <div className="col-lg-4 col-md-3 col-sm-2 col-xs-12 p-2">
                  <a href={`'/installation-details/${install.slug}'`}>
                    <img src={install.acf.installation_main_image.url} className="img-fluid" alt={install.title.rendered} /><br/>{install.title.rendered}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ProjectInstallations
