import React, { useState } from 'react';


const InstallCard = (props) => {
	
    return (
      <div className="installation">
		  	<div className="card" key={props.data.id}>
         	<a href={`/installation-details/${props.data.slug}`}><img src={props.data.acf.installation_main_image.url} className="card-img-top" alt={props.data.title.rendered}></img></a>
          <div className="card-body">
					<a href="##">
            <p className='card-title'>{props.data.title.rendered}</p>
          </a>
			  </div>
			</div>
      </div>
		)
}


export default InstallCard;